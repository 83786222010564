//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    tall: {
      type: Boolean,
      default: false,
    },
    cover: {
      type: Boolean,
      default: false,
    },
  },
};
