import { render, staticRenderFns } from "./UiImage.vue?vue&type=template&id=144a3602&scoped=true&"
import script from "./UiImage.vue?vue&type=script&lang=js&"
export * from "./UiImage.vue?vue&type=script&lang=js&"
import style0 from "./UiImage.vue?vue&type=style&index=0&id=144a3602&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "144a3602",
  null
  
)

export default component.exports