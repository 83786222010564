//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const WIDTH_PX = 1280;
const HEIGHT_PX = 660;

export default {
  data() {
    return {
      scaleX: 1,
      scaleY: 1,
    };
  },

  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development';
    },
    scale() {
      return Math.min(this.scaleX, this.scaleY);
    },
  },

  mounted() {
    this.updateScale();
    window.addEventListener('resize', this.updateScale);
  },

  beforeDestroy() {
    window.addEventListener('resize', this.updateScale);
  },

  methods: {
    updateScale() {
      this.scaleX = window.innerWidth / WIDTH_PX;
      this.scaleY = window.innerHeight / HEIGHT_PX;
    },
  },
};
