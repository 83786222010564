//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    date() {
      return this.$day.tz(process.env.DATE_START, process.env.TIMEZONE);
    },
    openingYear() {
      return this.date.year();
    },
    title() {
      return `Opening ${this.openingYear}`;
    },
    subtitle() {
      return `Apartments
              on sale now`;
    },
    text() {
      return `Show Suite
              10 Northcroft Street
              Takapuna`;
    },
  },
};
